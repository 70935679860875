/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from 'react'
import axios from 'axios'
import { PayData } from '../../../@types'
import { PLAID_SERVER_URI } from '../../../consts'
import Plaid from './Plaid'
import CollectEmail from './CollectEmail'
import FinalConfirm from './FinalConfirm'

interface Props {
  nextStep: () => void
  authState: string
  accessToken: string
  paymentData: PayData | null
  // eslint-disable-next-line no-unused-vars
  updatePaymentData: (body: Record<string, any>) => Promise<void>
}

export default function Authorize({
  nextStep,
  authState,
  accessToken,
  paymentData,
  updatePaymentData,
}: Props): JSX.Element {
  const [accountName, setAccountName] = useState<string>('')
  const [plaidDone, setPlaidDone] = useState<boolean>(Boolean(authState === 'EXISTING_ITEM'))
  const [emailDone, setEmailDone] = useState<boolean>(false)

  const getAccountName = async (accountId?: string): Promise<void> => {
    try {
      const {
        data: {
          accountName,
        },
      } = await axios.post(
        `${PLAID_SERVER_URI}/get-account-name`,
        accountId ? { accountId } : {},
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        },
      )
      
      setAccountName(accountName)
    } catch (error) {
      console.error(error)
    }
  }

  useEffect(() => {
    if (authState === 'EXISTING_ITEM') getAccountName()
  }, [authState]) // eslint-disable-line
  
  if (!plaidDone) {
    return (
      <Plaid
        accessToken={accessToken}
        getAccountName={getAccountName}
        callback={() => setPlaidDone(true)}
      />
    )
  }
  
  if (!emailDone) {
    return (
      <CollectEmail
        paymentData={paymentData}
        updatePaymentData={updatePaymentData}
        callback={() => setEmailDone(true)}
      />
    )
  }

  return (
    <FinalConfirm
      nextStep={nextStep}
      paymentData={paymentData}
      accountName={accountName}
      editAccount={() => {
        setPlaidDone(false)
        setEmailDone(false)
      }}
    />
  )
} 
