/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable max-len */
import React, { Fragment, useEffect, useState } from 'react'
import axios from 'axios'
import { DialogWrapper, StepContext, Loader } from '@everybyte-io/components'
import { getQueryStringValue, parentComms } from '../functions'
import Summary from '../components/PayJourney/Summary'
import OTP from '../components/PayJourney/OTP'
import Authorize from '../components/PayJourney/Authorize'
import Checkout from '../components/PayJourney/Checkout'
import { JOURNEY_SERVER_URI, PLAID_SERVER_URI } from '../consts'
import { PayData } from '../@types'
import logo from '../assets/everybyte.png'

const Pay = (): JSX.Element => {
  const sessionId = getQueryStringValue('sessionId')

  const [loading, setLoading] = useState(false)
  
  const [authState, setAuthState] = useState<string>('')
  const [accessToken, setAccessToken] = useState<string>('')
  const [paymentData, setPaymentData] = useState<PayData | null>(null)

  const getPaymentData = async (): Promise<void> => {
    setLoading(true)

    try {
      const {
        data: {
          session,
        },
      } = await axios.get(
        `${JOURNEY_SERVER_URI}/${sessionId}/public`,
      )
  
      setPaymentData(session)
    } catch (error) {
      console.error(error)
    }

    setLoading(false)
  }

  const updatePaymentData = async (body: Record<string, any> = {}, token?: string): Promise<void> => {
    try {
      const {
        data: {
          session,
        },
      } = await axios.patch(
        `${JOURNEY_SERVER_URI}/${sessionId}`,
        body,
        {
          headers: {
            Authorization: `Bearer ${token ?? accessToken}`,
          },
        },
      )

      setPaymentData(session)
    } catch (error) {
      console.error(error)
    }
  }

  const getAuthState = async (token?: string): Promise<void> => {    
    try {
      const {
        data: {
          authState,
        },
      } = await axios.post(
        `${PLAID_SERVER_URI}/get-auth-state`,
        {
          products: ['auth'],
        },
        {
          headers: {
            Authorization: `Bearer ${token ?? accessToken}`,
          },
        },
      )

      setAuthState(authState)
    } catch (error) {
      console.error(error)
    }
  }

  useEffect(() => {
    if (sessionId && !paymentData) getPaymentData()
  }, [sessionId, paymentData]) // eslint-disable-line

  const otpCallback = async (token: string, auth0UserId: string): Promise<void> => {
    setAccessToken(token)
    await updatePaymentData({ auth0UserId }, token)
    await getAuthState(token)
  }

  const onClickClose = (): void => {
    if (window.confirm('Are you sure you want to abort this payment?')) {
      parentComms('abort')
      parentComms('close')
    }
  }

  return (
    <DialogWrapper
      numberOfPhases={4}
      preventBackAtSteps={[3, 4]}
      onClickClose={onClickClose}
      Image={() => <img src={logo} alt='Everybyte' height='42' />}
    >
      <StepContext.Consumer>
        {({ step, increment }): JSX.Element | null => {
          return (
            <Fragment>
              {loading ? (
                <div style={{ width: '100%', height: '100%', display: 'grid', placeItems: 'center' }}>
                  <Loader label='Getting payment information...' />
                </div>
              ) : step === 1 ? (
                <Summary nextStep={increment} paymentData={paymentData} onClickClose={onClickClose} />
              ) : step === 2 ? (
                <OTP
                  nextStep={increment}
                  otpCallback={otpCallback}
                />
              ) : step === 3 ? (
                <Authorize
                  nextStep={increment}
                  authState={authState}
                  accessToken={accessToken}
                  paymentData={paymentData}
                  updatePaymentData={updatePaymentData}
                />
              ) : step === 4 ? (
                <Checkout
                  sessionId={sessionId}
                  accessToken={accessToken}
                  paymentData={paymentData}
                />
              ) : null}
            </Fragment>
          )
        }}
      </StepContext.Consumer>
    </DialogWrapper>
  )
}

export default Pay
