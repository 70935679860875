import React from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'
import Pay from './pages/Pay'

function App(): JSX.Element {
  return (
    <div>
      <Switch>
        <Route exact path='/' component={Pay} />
        <Route>
          <Redirect to='/' />
        </Route>
      </Switch>
    </div>
  )
}

export default App
