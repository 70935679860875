import { createTheme } from '@mui/material/styles'
import { Palette, PaletteColor, TypeBackground } from '@mui/material/styles/createPalette'

interface CustomPaletteColor extends PaletteColor {
  main: string
}

interface CustomBackground extends TypeBackground {
  default: string
}

interface CustomPalette extends Palette {
  primary: CustomPaletteColor,
  background: CustomBackground,
}

declare module '@mui/styles' {
  interface DefaultTheme {
    palette: CustomPalette,
    spacing: (factor?: number) => string, // eslint-disable-line no-unused-vars
  }
}

const theme = createTheme({
  palette: {
    primary: {
      main: 'rgb(0,0,0)',
    },
    background: {
      default: 'rgba(245, 246, 250, 0.4)',
    },
  },
  typography: {
    fontFamily: ['Cern', 'Helvetica', 'Arial', 'sans-serif'].join(','),
  },
})

export default theme
