import React from 'react'
import { Button, Card } from '@everybyte-io/components'
import { makeStyles } from '@mui/styles'
import {
  AccountCircleOutlined,
  DescriptionOutlined,
  MoneyOutlined,
  EmailOutlined,
} from '@mui/icons-material'
import { PayData } from '../../../@types'

const useStyles = makeStyles(
  (theme) => ({
    image: {
      width: '10rem',
      margin: '0 auto auto auto',
    },
    cardContainer: {
      margin: '1em 0',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'start',
      flexGrow: 1,
    },
    buttonContainer: {
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
    },
    divider: {
      display: 'flex',
      alignItems: 'center',
      opacity: 0.5,
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(),
      width: '100%',
    },
    border: {
      opacity: 0.5,
      borderBottom: '1px solid black',
      width: '100%',
    },
    content: {
      padding: '0 10px 0 10px',
    },
  }),
  { name: 'FinalConfirm' },
)

interface Props {
  nextStep: () => void
  paymentData: PayData | null
  accountName: string
  editAccount: () => void
}

export default function FinalConfirm({
  nextStep,
  paymentData,
  accountName,
  editAccount,
}: Props): JSX.Element {
  const classes = useStyles()

  const clickConfirm = (): void => {
    nextStep()
  }

  const clickEdit = (): void => {
    editAccount()
  }

  const accountInfoCard = [
    {
      key: 'Transfer from:',
      value: accountName ?? '-',
      icon: () => (<AccountCircleOutlined />)
    },
    {
      key: 'Receipt email:',
      value: paymentData?.receiptEmail ?? '-',
      icon: () => (<EmailOutlined />)
    },
  ]

  const orderInfoCard = [
    {
      key: 'Transfer to:',
      value: paymentData?.recipientName ?? '-',
      icon: () => <AccountCircleOutlined />,
    },
    {
      key: 'For:',
      value: paymentData?.description ?? '-',
      icon: () => <DescriptionOutlined />,
    },
    {
      key: 'Amount:',
      value: `${paymentData?.amount.label ?? 0} ${paymentData?.currency.toUpperCase() ?? ''}`,
      icon: () => <MoneyOutlined />,
    },
  ]

  return (
    <>
      <div className={classes.cardContainer}>
        <Card backgroundColor='#e0effd' label='Account information' data={accountInfoCard} />
        <Card backgroundColor='#f3f0f9' label='Order information' data={orderInfoCard} />
      </div>

      <div className={classes.buttonContainer}>
        <Button label='Confirm' invert textAlign='center' onClick={clickConfirm} />
        <div className={classes.divider}>
          <div className={classes.border} />
          <span className={classes.content}>OR</span>
          <div className={classes.border} />
        </div>
        <Button label='Edit account information' textAlign='center' onClick={clickEdit} />
      </div>
    </>
  )
}
