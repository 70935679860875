import React from 'react'
import { LinkButton } from '@everybyte-io/components'
import SweetAlert from 'react-bootstrap-sweetalert'
import { parentComms } from '../../../functions'
import { PayData } from '../../../@types'

interface Props {
  isSuccess: boolean
  notSuccessMessage: string
  paymentData: PayData | null
}

export default function Finished({ isSuccess, notSuccessMessage, paymentData }: Props): JSX.Element {
  const clickConfirm = (): void => {
    if (isSuccess) {
      parentComms('success', paymentData ?? {})
    } else {
      parentComms('fail', { error: notSuccessMessage })
    }
    parentComms('close')
  }

  if (isSuccess) {
    return (
      <SweetAlert
        success
        title='Success!'
        onConfirm={() => null}
        customButtons={<LinkButton label='Confirm' onClick={clickConfirm} />}
      >
        Payment complete!<br />
        You will get a receipt via Email.
      </SweetAlert>
    )
  }

  return (
    <SweetAlert
      error
      title='Failed!'
      onConfirm={() => null}
      customButtons={<LinkButton label='Confirm' onClick={clickConfirm} />}
    >
      An error occurred, the payment failed!<br />
      Please try again, or contact us for further instructions.
    </SweetAlert>
  )
}
