/* eslint-disable no-unused-vars */
import React from 'react'
import ReactPhoneInput, { PhoneInputProps as OgPhoneInputProps } from 'react-phone-input-2'
import 'react-phone-input-2/lib/material.css'

export interface PhoneInputProps extends OgPhoneInputProps {
  /**
   * country
   */
  country?: string
  /**
   * phone number
   */
  value: string
  /**
   * event that handle the change of the phone number
   */
  onChange: (phone: string) => void
  /**
   * style of the input
   */
  style?: React.CSSProperties
  /**
   * boolean
   */
  required?: boolean
  /**
   * boolean
   */
  autoFocus?: boolean
}

function PhoneInput({
  country = 'us',
  value,
  onChange,
  style = {},
  required = false,
  autoFocus = false,
  ...props
}: PhoneInputProps): JSX.Element {
  return (
    <ReactPhoneInput
      country={country}
      value={value}
      onChange={onChange}
      inputStyle={{ width: '250px', ...style }}
      inputProps={{
        required,
        autoFocus,
      }}
      {...props}
    />
  )
}

export default PhoneInput
