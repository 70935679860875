import React, { useEffect } from 'react'
import axios from 'axios'
import { Loader } from '@everybyte-io/components'
import { JOURNEY_SERVER_URI } from '../../../consts'

interface Props {
  sessionId: string
  accessToken: string
  setIsSuccess: React.Dispatch<React.SetStateAction<boolean>>
  setNotSuccessMessage: React.Dispatch<React.SetStateAction<string>>
}

export default function Stripe({
  sessionId,
  accessToken,
  setIsSuccess,
  setNotSuccessMessage,
}: Props): JSX.Element {
  const pay = async (): Promise<void> => {
    try {
      await axios.post(
        `${JOURNEY_SERVER_URI}/pay-with-stripe`,
        {
          sessionId,
        },
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        },
      )
      
      setIsSuccess(true)
    } catch (error: any) { // eslint-disable-line
      console.error(error)
      setNotSuccessMessage(error?.response?.message ?? error?.message ?? '-')
    }
  }

  useEffect(() => {
    pay()
  }, []) // eslint-disable-line

  return (
    <div style={{ width: '100%', height: '100%', display: 'grid', placeItems: 'center' }}>
      <Loader label='Processing payment...' />
    </div>
  )
}
