/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState } from 'react'
import axios from 'axios'
import { ProcessOTP } from '@everybyte-io/components'
import PhoneInput from '../generic/PhoneInput'
import { AUTH_SERVER_URI } from '../../consts'
import otpImage from '../../assets/otp3.jpeg'

interface Props {
  nextStep: () => void
  // eslint-disable-next-line no-unused-vars
  otpCallback: (accessToken: string, auth0UserId: string) => Promise<void>
}

const SCOPE = 'create:plaid-link update:plaid-link create:plaid-item read:plaid-item update:payments'

export default function OTP({
  nextStep,
  otpCallback,
}: Props): JSX.Element {
  const [auth0UserId, setAuth0UserId] = useState('')

  // eslint-disable-next-line no-unused-vars
  const sendOtp = async (phone: string, cb: (error?: string) => void): Promise<void> => {
    try {
      const {
        data: {
          auth0UserId: id,
        },
      } = await axios.post(
        `${AUTH_SERVER_URI}/send-otp`,
        {
          scope: SCOPE,
          phoneNumber: phone.indexOf('+') === 0 ? phone : `+${phone}`,
        },
      )
      
      setAuth0UserId(id)
      cb()
    } catch (error) {
      console.error(error)
      cb('Invalid phone number')
    }
  }

  // eslint-disable-next-line no-unused-vars
  const verifyOtp = async (phone: string, otp: string, cb: (error?: string) => void): Promise<void> => {
    try {
      const {
        data: {
          accessToken,
        },
      } = await axios.post(
        `${AUTH_SERVER_URI}/verify-otp`,
        {
          scope: SCOPE,
          phoneNumber: phone.indexOf('+') === 0 ? phone : `+${phone}`,
          otp,
        },
      )
      
      await otpCallback(accessToken, auth0UserId)
      cb()
    } catch (error) {
      console.error(error)
      cb('Invalid code')
    }
  }

  const [phone, setPhone] = useState('')

  return (
    <ProcessOTP
      nextStep={nextStep}
      requirePhone={true}
      defaultCountry='us'
      sendOtp={sendOtp}
      verifyOtp={verifyOtp}
      forcePhone={phone}
      imageSrc={otpImage}
    >
      <PhoneInput value={phone} onChange={(val) => setPhone(val)} />
    </ProcessOTP>
  )
}
