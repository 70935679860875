/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable max-len */
import React, { useState } from 'react'
import { Button, Input, Loader } from '@everybyte-io/components'
import { makeStyles } from '@mui/styles'
import { FormHelperText, Typography } from '@mui/material'
import { Email } from '@mui/icons-material'
import ReceiptImage from '../../../assets/receipt.png'
import { validateAndSanitizeEmail } from '../../../functions'
import { PayData } from '../../../@types'

const useStyles = makeStyles(
  () => ({
    inputContainer: {
      width: '90%',
      margin: '0 auto',
      display: 'flex',
      flexDirection: 'column',
      flexGrow: 1,
    },
    image: {
      width: '10rem',
      margin: '0 auto 1rem auto',
    },
    input: {
      '& .MuiInputBase-root': {
        backgroundColor: 'rgb(252, 252, 252) !important',
      },
    },
    text: {
      fontSize: '0.9rem !important',
    },
    buttonContainer: {
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
    },
  }),
  { name: 'CollectEmail' },
)

interface Props {
  paymentData: PayData | null
  // eslint-disable-next-line no-unused-vars
  updatePaymentData: (body: Record<string, any>) => Promise<void>
  callback?: () => void
}

export default function CollectEmail({ paymentData, updatePaymentData, callback }: Props): JSX.Element {
  const classes = useStyles()

  const [email, setEmail] = useState<string>(paymentData?.receiptEmail ?? '')
  const [loading, setLoading] = useState<boolean>(false)
  const [error, setError] = useState<false | string>(false)

  const updateEmail = async (): Promise<void> => {
    setError(false)
    setLoading(true)
    const validatedEmail = validateAndSanitizeEmail(email)

    if (validatedEmail) {
      await updatePaymentData({ receiptEmail: validatedEmail })
      if (callback) callback()
    } else {
      setLoading(false)
      setError('Email is invalid!')
    }
  }

  return (
    <>
      <div className={classes.inputContainer}>
        <img src={ReceiptImage} alt='' className={classes.image} />

        <Input
          value={email}
          setValue={setEmail}
          type='email'
          label='Email'
          placeholder='john.doe@example.com'
          icon={Email}
        />

        {error ? (
          <FormHelperText error sx={{ display: 'flex', justifyContent: 'center' }}>
            {error}
          </FormHelperText>
        ) : (
          <Typography align='center' className={classes.text}>
            Please enter an email address. A receipt for this payment would be sent to the provided email
            address.
          </Typography>
        )}
      </div>

      <div className={classes.buttonContainer}>
        {loading ? (
          <Loader label='Updating email...' />
        ) : (
          <Button label='Continue' invert textAlign='center' onClick={updateEmail} />
        )}
      </div>
    </>
  )
}
