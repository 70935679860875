// this function first sanitizes the email,
// then validates it using a regular expression,
// the retruned result is either false or the sanitized email
export default function validateAndSanitizeEmail(str: string): string | false {
  // sanitize
  const sanitizedEmail = str.toLowerCase()

  // verify data (using regular expressions)
  // eslint-disable-next-line
  const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  const isVerified = emailRegex.test(sanitizedEmail)

  if (isVerified) {
    return sanitizedEmail
  } else {
    return false
  }
}
