import React, { useState } from 'react'
import { Button, LinkButton, Card } from '@everybyte-io/components'
import { makeStyles } from '@mui/styles'
import { AccountCircleOutlined, DescriptionOutlined, MoneyOutlined } from '@mui/icons-material'
import SweetAlert from 'react-bootstrap-sweetalert'
import PayImage from '../../assets/pay.png'
import { PayData } from '../../@types'

const useStyles = makeStyles(
  (theme) => ({
    image: {
      width: '10rem',
      margin: '0 auto auto auto',
    },
    cardContainer: {
      margin: '1em 0',
      display: 'flex',
      flexDirection: 'column',
      flexGrow: 1,
    },
    buttonContainer: {
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
    },
    divider: {
      display: 'flex',
      alignItems: 'center',
      opacity: 0.5,
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(),
      width: '100%',
    },
    border: {
      opacity: 0.5,
      borderBottom: '1px solid black',
      width: '100%',
    },
    content: {
      padding: '0 10px 0 10px',
    },
  }),
  { name: 'Summary' },
)

interface Props {
  nextStep: () => void
  paymentData: PayData | null
  onClickClose: () => void
}

export default function Summary({ nextStep, paymentData, onClickClose }: Props): JSX.Element {
  const classes = useStyles()
  const [displayError, setDisplayError] = useState<false | string>(false)

  const clickConfirm = (): void => {
    if (paymentData && paymentData.active) {
      nextStep()
    } else if (paymentData && !paymentData.active) {
      console.warn('This payment session is inactive, please verify the session ID')
      setDisplayError('This payment session is inactive, check logs for more information.')
    } else {
      console.warn('No payment information was provided, please verify query: ?sessionId={SESSION_ID}')
      setDisplayError('No payment information was provided, check logs for more information.')
    }
  }

  const clickDeny = (): void => {
    onClickClose()
  }

  const orderInfoCard = [
    {
      key: 'Transfer to:',
      value: paymentData?.recipientName ?? '-',
      icon: () => <AccountCircleOutlined />,
    },
    {
      key: 'For:',
      value: paymentData?.description ?? '-',
      icon: () => <DescriptionOutlined />,
    },
    {
      key: 'Amount:',
      value: `${paymentData?.amount.label ?? 0} ${paymentData?.currency.toUpperCase() ?? ''}`,
      icon: () => <MoneyOutlined />,
    },
  ]

  return (
    <>
      <div className={classes.cardContainer}>
        <img src={PayImage} alt='' className={classes.image} />
        <Card backgroundColor='#f3f0f9' label='Order information' data={orderInfoCard} />
      </div>

      <div className={classes.buttonContainer}>
        <Button label='Confirm' invert textAlign='center' onClick={clickConfirm} />
        <div className={classes.divider}>
          <div className={classes.border} />
          <span className={classes.content}>OR</span>
          <div className={classes.border} />
        </div>
        <Button label='Deny' textAlign='center' onClick={clickDeny} />
      </div>

      {displayError && (
        <SweetAlert
          error
          title='Error!'
          onConfirm={() => null}
          customButtons={<LinkButton label='Confirm' onClick={() => setDisplayError(false)} />}
        >
          {displayError}
        </SweetAlert>
      )}
    </>
  )
}
