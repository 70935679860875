import { getQueryStringValue, formatUrl } from './functions'

const sandbox = getQueryStringValue('sandbox')

const {
  REACT_APP_JOURNEY_SERVER_URI = 'http://localhost:6969',
  REACT_APP_AUTH_SERVER_URI = 'http://localhost:8080',
  REACT_APP_PLAID_SERVER_URI = 'http://localhost:1996',
} = process.env

const JOURNEY_SERVER_URI = formatUrl(REACT_APP_JOURNEY_SERVER_URI, sandbox)
const AUTH_SERVER_URI = formatUrl(REACT_APP_AUTH_SERVER_URI, sandbox)
const PLAID_SERVER_URI = formatUrl(REACT_APP_PLAID_SERVER_URI, sandbox)

export {
  JOURNEY_SERVER_URI,
  AUTH_SERVER_URI,
  PLAID_SERVER_URI,
}
