import React, { useState } from 'react'
import { PayData } from '../../../@types'
import Finished from './Finished'
import Stripe from './Stripe'

interface Props {
  sessionId: string
  accessToken: string
  paymentData: PayData | null
}

export default function Checkout({
  sessionId,
  accessToken,
  paymentData,
}: Props): JSX.Element {
  const [isSuccess, setIsSuccess] = useState<boolean>(false)
  const [notSuccessMessage, setNotSuccessMessage] = useState('')

  return isSuccess || notSuccessMessage ? (
    <Finished
      isSuccess={isSuccess}
      notSuccessMessage={notSuccessMessage}
      paymentData={paymentData}
    />
  ) : (
    <Stripe
      sessionId={sessionId}
      accessToken={accessToken}
      setIsSuccess={setIsSuccess}
      setNotSuccessMessage={setNotSuccessMessage}
    />
  )
}
