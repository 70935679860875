const getQueryStringValue = (key: string): string => {
  const encoded = encodeURIComponent(key).replace(/[.+*]/g, '\\$&')
  const regEx = new RegExp(`^(?:.*[&\\?]${encoded}(?:\\=([^&]*))?)?.*$`, 'i')
  const query = window.location.search.replace(regEx, '$1')
  const decoded = decodeURIComponent(query)

  return decoded
}

export default getQueryStringValue
